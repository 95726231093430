.miniatura-imovel {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 2rem;
	position: relative;

	& .miniatura-imovel-img{
		border-radius: .3rem .3rem 0 0;
		display: block;
		width: 100%;
		background-color: $gray5;
		user-select: none;
		border: solid 1px $gray4;
		border-bottom: none;
		position: relative;
		padding-top: 85%;
		overflow: hidden;

		& img{
			display: block;
			width: 420px;
			height: 420px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			object-fit: cover;
		}

		& .sta_cod{
			position: absolute;
			top: .5rem;
			right: .5rem;
			z-index: 9;
			display: flex;
			gap: 0.5rem;
			align-items: center;
			justify-content: end;

			& .miniatura_status_cod{
				& p{
					background-color: rgba(white, .9);
					border: solid 1px $gray4;
					font-weight: 500;
					color: $colorSecondary;
					font-size: .75rem;
					padding: .1rem .5rem;
					border-radius: .2rem;
					margin: 0;
					line-height: normal;
				}
			}
		}
	}

	& .miniatura-imovel-detalhe{
		display: block;
		border: solid 1px $gray4;
		border-radius: 0 0 .3rem .3rem;
		width: 100%;
		padding: 1rem;
		background-color: white;
		@include border-box;
		position: relative;

		& .miniatura_tipo{
			padding: .1rem .7rem;
			text-transform: capitalize;
			font-size: .75rem;
			font-weight: 500;
			color: $colorSecondary;
			background-color: white;
			border-radius: .2rem;
			border: solid 1px $gray4;
			width: fit-content;
			margin: 0;
		}

		& .miniatura-titulo{
			display: block;
			width: 100%;

			& .miniatura_title{
				text-transform: capitalize;
				font-size: .9rem;
				font-weight: 600;
				color: $gray1;
				margin: 0;
				line-height: 1.5rem;
				height: 3rem;
			}

			& .miniatura_endereco{
				display: block;
				width: 100%;

				& p{
					text-transform: initial;
					font-size: .8rem;
					font-weight: 500;
					color: $gray2;
					& i{
						font-size: .7rem;
						font-weight: 400;
						color: $colorPrimary;
					}
				}
			}
		}

		& .miniatura-imovel-btns-icons{
			width: 100%;
			height: 4.5rem;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 1rem;
			overflow: hidden;

			& ul{
				& li{
					color: $gray2;
					font-size: .8rem;
					font-weight: 400;

					& span{
						color: $colorPrimary;
						font-size: 1rem;
						font-weight: 600;
					}
				}
			}
		}

		& .miniatura-preco{
			display: block;
			width: 100% !important;

			& .aca_tip{
				font-size: .8rem;
				font-weight: 400;
				color: $gray2;
				padding: 0;
				margin: 0;
			}
			
			& p{
				padding: 0;
				margin: 0;
				text-align: left;
				font-size: 1.2rem;
				font-weight: 600;
				color: $colorSecondary;
			}
		}
	}
}

.splide__arrow--prev{
	left: -2rem !important;
}

.splide__arrow--next{
	right: -2rem !important;
}

/* SÓ SE APLICA NO PC */
@media all and (min-width: 1201px) and (max-width: 1366px){
	.splide__arrow--prev{
		left: 2rem !important;
	}
	
	.splide__arrow--next{
		right: 2rem !important;
	}
}

@media all and (min-width: 1024px) and (max-width: 1200px){
	.splide__arrow--prev{
		left: 0 !important;
	}
	
	.splide__arrow--next{
		right: 0 !important;
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.miniatura-imovel {
		& .miniatura-imovel-img{
			padding-top: 90%;
		}

		& .miniatura-imovel-detalhe{
			& .miniatura-preco{
				& .aca_tip{
					margin-top: 1rem;
				}
			}
		}
	}
	
}

