.footer1 {
	box-shadow: 0 0 15px 1px rgba($gray1, .15);
	width: 100%;
	display: block;
	background-color: white;

	& .ContainerFooter {
		display: flex;
		flex-direction: row;
		gap: 1.5rem;
		width: 100%;

		& .BoxItemFooter{
			width: 100%;
			display: block;

			& .BlocoLogo{
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 1rem;

				& .logo_footer{
					width: 100%;
					display: block;
					text-align: center;
					& img{
						width: 180px;
						height: 100px;
						user-select: none;
						-webkit-user-drag: none;
					}
				}

				& p{
					font-size: .9rem;
					font-weight: 600;
					color: $colorSecondary;
					margin: 0;
					margin-bottom: .5rem;
					padding: 0;
					line-height: inherit;
					user-select: none;
				}

				& .rs_footer{
					display: flex;
					flex-direction: row;
					gap: 1.5rem;

					& a{
						& p{
							padding: .5rem;
							margin: 0;
							line-height: inherit;
							border: solid 1px $colorTerciary;
							border-radius: .3rem;
							font-weight: 400;

							& i{
								font-size: 1.2rem;
								color: $colorSecondary;
								transition: color .2s ease-in-out;
								line-height: inherit;
							}
						}
						&:hover{
							& p{
								& i{
									color: $colorPrimary;
								}
							}	
						}
					}
				}
			}

			& .ContentItemFooter{
				display: flex;
				flex-direction: column;
				gap: 1rem;

				& li{
					& h3{
						font-size: 1rem;
						color: $colorSecondary;
						font-weight: 600;
						margin: 0;
						padding: 0;
						user-select: none;
					}
					
					& a{
						width: fit-content;
						display: block;
						
						& p{
							font-size: .9rem;
							color: $colorSecondary;
							margin: 0;
							padding: 0;
							line-height: normal;
							transition: color .2s ease-in-out;
							user-select: none;
							
							& i{
								color: $colorPrimary;
								font-weight: 400;
							}
						}

						&:hover{
							& p{
								color: $colorPrimary;
							}
						}
					}

					& p{
						font-size: .9rem;
						color: $colorSecondary;
						margin: 0;
						padding: 0;
						line-height: normal;
						transition: color .2s ease-in-out;
						user-select: none;
						
						& i{
							color: $colorPrimary;
							font-weight: 400;
						}
					}
				}
			}
		}
	}

	& .ContainerInferiorFooter{
		width: 100%;
		
		& .ItemContainerInferior{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			& p{
				margin: 0;
				font-size: .9rem;
				color: $colorSecondary;
				user-select: none;

				& a{
					& span{
						font-weight: 600;
						font-size: .9rem;
						transition: color .2s ease-in-out;
					}

					&:hover{
						& span{
							color: $colorPrimary;
						}
					}
				}
			}
		}
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin footer--large() {}

/* 
	MEDIUM SCREEN 
	max-width: 1300px
	min-width: 1024px
*/
@mixin footer--medium() {}

/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin footer--tablet-landscape() {}

/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin footer--tablet-portrait() {}

/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin footer--smart-landscape() {}

/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin footer--smart-portrait() {}

/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin footer--smart-old() {}

@media all and (max-width: 1023px){
	.footer1 {
		& .ContainerFooter {
			flex-direction: column;
			gap: 3rem;
	
			& .BoxItemFooter{
				& .ContentItemFooter{
					& li{
						& h3{
							font-size: 1.1rem;
						}
						
						& a{
							& p{
								font-size: 1rem;
							}
						}
	
						& p{
							font-size: 1rem;
						}
					}
				}
			}
		}
	
		& .ContainerInferiorFooter{
			& .ItemContainerInferior{
				flex-direction: column;
				gap: 1rem;
	
				& p{
					font-size: 1rem;
					text-align: center;
					line-height: 2rem;

					& a{
						& span{
							font-size: 1rem;
						}
					}
				}
			}
		}
	}
}