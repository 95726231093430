.nav {
	display: block;
	width: 20vw;
	position: fixed;
	top: 0;
	right: -1000px;
	background-color: white;
	z-index: 99991;
	padding: 0 1.5rem;
	box-shadow: -5px 0 10px -5px rgba(black, .2);

	& button {
		background-color: transparent;
		color: $colorPrimary;
		border: none;
		font-size: 2rem;
		cursor: pointer;

		& i {
			line-height: inherit;
		}
	}

	& .nav-flow {
		min-height: 50vh;
		height: 100vh;
	}

	& a {

		& .nav-menu {
			color: $colorSecondary;
			font-size: 1rem;
			margin: 0;
			padding: .5rem 0;
			@include border-box;
			transition: all .2s ease-in-out;
		}

		&:hover {
			& .nav-menu {
				color: $colorPrimary;
			}
		}
	}
}

@media all and (max-width: 1023px) {
	.nav {
		width: 100%;
		overflow: auto;
		background-color: rgba(white, .99);

		& .nav-menu {
			color: $gray1;
			font-size: 1.4rem;
			margin: 0;
			padding: .6rem 1rem;
			@include border-box;
			transition: all .25s;
		}

		& .nav-flow {
			min-height: 50vh;
			height: 110vh;
		}

		& .content {
			padding-bottom: 10rem !important;
		}
	}
}