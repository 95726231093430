$tamanho_img_corretor: 9rem;

.miniatura-corretor {

	padding-bottom: 20px;
	@include border-box;


	& .miniatura-corretor-descricao {
		@include border-box;
		width: 100%;
		
		& h3{
			font-weight: 600;
			font-size: 1.5rem;
			padding-left: 2rem;
		}
		& .box_corretor{
			position: relative;
			background-color: $colorPrimary;
			border-top-left-radius: 18px;
			border-bottom-left-radius: 18px;
			padding: 0.5rem 1.5rem;
			color: white;
			font-size: 1.2rem;
			font-weight: 600;
			width: 100%;
			max-width: 22rem;
			& i{
				font-size: 2.3rem;
				vertical-align: middle !important;
			}
			& .miniatura-corretor-img {
				position: absolute;
				width: $tamanho_img_corretor;
				right: -0.5rem;
				top: -3rem;
				& img {
					display: block;
					width: 100%;
		
					@include border-radius(50%);
				}
			}
		}
	}
}

@media all and (max-width: 1023px){
	.miniatura-corretor {

		padding-bottom: 20px;
		@include border-box;
	
	
		& .miniatura-corretor-descricao {
			@include border-box;
			width: 100%;
			
			& h3{
				font-weight: 600;
				font-size: 1.5rem;
				padding-left: 2rem;
			}
			& .box_corretor{
				position: relative;
				background-color: $colorPrimary;
				border-top-left-radius: 18px;
				border-bottom-left-radius: 18px;
				padding: 0.5rem 1.5rem;
				color: white;
				font-size: 1.2rem;
				font-weight: 600;
				width: 90%;
				max-width: 22rem;
				& i{
					font-size: 2.3rem;
					vertical-align: middle !important;
				}
				& .miniatura-corretor-img {
					position: absolute;
					width: 7rem;
					right: -0.5rem;
					top: -2rem;
					& img {
						display: block;
						width: 100%;
			
						@include border-radius(50%);
					}
				}
			}
		}
	}
}