.busca {
	background-color: rgba(white, .99);
	box-shadow: 0 0 10px 1px rgba(black, .15);
	border-radius: .3rem;
	padding: 2rem;
	display: block;
	width: fit-content;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 15%;
	transform: translate(-15%, -50%);
	z-index: 2;

	& .slogan{
		display: block;
		width: 100%;
		margin: 0;

		& h1{
			font-size: 1.5rem;
			font-weight: 600;
			color: $colorSecondary;
			text-align: left;
			line-height: 2rem;
			margin: 0;
		}
	
		& h2{
			font-size: 1rem;
			font-weight: 400;
			color: $gray2;
			text-align: left;
		}
	}

	& .busca_inteligente_index{
		display: block;
		width: 80%;
		margin: 0;

		& .busca_inteligente{
			display: block;
			width: 100%;
			border: solid 1px $colorTerciary;
			border-radius: .3rem;
			position: relative;
		
			& input{
				width: 75%;
				display: inline-block;
				vertical-align: middle;
				background-color: transparent;
				padding: 1rem;
				border: none;
				color: $gray1;
				&::placeholder{
					color: $gray3 !important;
				}
			}
		
			& .btn_buscar{
				background-color: $colorPrimary;
				width: 25%;
				display: inline-block;
				vertical-align: middle;
				border: none;
				user-select: none;
				cursor: pointer;
				border-radius: .3rem;
				transition: all .2s ease-in-out;
				padding: 0;
				margin: 0;

				& p{
					color: white;
					font-size: 1rem;
					font-weight: 600;
					margin: 0;
					text-align: center;
					padding: 1rem 0;
					line-height: normal;

					& i{
						color: white;
						font-size: .8rem !important;
						font-weight: 400;
					}
				}

				&:hover{
					background-color: darken($colorPrimary, 5);
				}
			}
		}
	}

	& .filtros_avancados_home{
		display: block;
		width: fit-content;
		margin: 0;
		
		& p{
			margin: 0;
			padding: .5rem 1.5rem;
			color: $colorSecondary;
			font-size: .9rem;
			font-weight: 500;
			transition: all .2s ease-in-out;
			cursor: pointer;
			user-select: none;
			background-color: transparent;
			border: solid 1px $colorTerciary;
			border-radius: .3rem;

			& i{
				transition: all .2s ease-in-out;
				color: $colorPrimary;
			}
			
			&:hover{
				background-color: white;
				border-color: $colorPrimary;
				& i{
					color: $colorPrimary;
				}
			}
		}

	}

	& .busca-filtro{
		width: 100%;
		display: block;

		& .bloco_busca{
			padding: 0;
			margin: 0;
			text-align: center;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 1rem;
			width: 100%;

			& .item_busca_home{
				width: 100%;

				& input{
					width: 100%;
				}

				& .btn-home{
					padding: .6rem 0;
					border-radius: .3rem;
					width: 100%;
					display: inline-block;
					vertical-align: middle;
					background-color: $colorPrimary;
					color: white;
					font-size: 1rem;
					font-weight: 600;
					border: none;
					cursor: pointer;
					transition: all .2s ease-in-out;

					&:hover{
						background-color: darken($colorPrimary, 5);
					}
				}
			}
		}
	}
}

.botao_buscar_int{
	text-align: center;
	padding: .45rem 0;
	border: none;
	border-radius: .3rem;
	margin-top: .15rem;
	background-color: $colorPrimary;
	color: white;
	font-size: 1.2rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;
	
	&:hover{
		background-color: darken($colorPrimary, 5);
	}
}

.botao_limpar_int{
	text-align: center;
	padding: .45rem 0;
	border: solid 1px $colorTerciary;
	border-radius: .3rem;
	margin-top: .15rem;
	background-color: white;
	color: $gray1;
	font-size: 1.2rem;
	font-weight: 500;
	cursor: pointer;
	transition: border-color .2s ease-in-out;
	
	&:hover{
		border-color: $colorPrimary;
	}
}

.btns_busca_interna_mobile{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: .5rem;
}

@media all and (min-width: 1024px) and (max-width: 1366px){

	.busca {
		width: 65%;

		& .slogan{
			& h1{
				font-size: 1.2rem;
			}
		
			& h2{
				font-size: 1rem;
			}
		}

		& .busca_inteligente_index{
			width: 100%;
	
			& .busca_inteligente{
				& input{
					padding: .7rem 1rem;
					
				}
			
				& .btn_buscar{
					& p{
						font-size: .9rem;
						padding: .7rem 0;
						
					}
				}
			}
		}
	}
	
}

@media all and (max-width: 1023px){

	#busca_container {
		display: block;
		width: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		@include border-box;
		z-index: 99999;

		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}
	
	.btn_mostrar_mais_busca_interna{
		text-align: center;
		width: 50%;
		background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
		color: white;
		font-size: 1rem;
		font-weight: 600;
		border-radius: 2rem;
		border: solid 2px white;
		padding: .5rem .8rem;
		box-shadow: 5px 7px 15px rgba(black, .3);
		cursor: pointer;
	}

	.busca {
		background-color: white;
		width: 90%;
		margin: 5rem auto;
		position: inherit;
		padding: 1rem;
		top: inherit;
		left: inherit;
		transform: inherit;

		& .slogan{
			width: 100%;
			
			& h1{
				font-size: 1.2rem;
				text-align: left;
				line-height: 1.75rem;
			}
		
			& h2{
				font-size: .9rem;
				text-align: left;
				line-height: 1.75rem;
			}
		}

		& .busca_inteligente_index{
			width: 100%;

			& .busca_inteligente{
				& input{
					width: 65%;
					padding: .6rem 1rem;
				}
			
				& .btn_buscar{
					width: 35%;

					& p{
						font-size: .85rem;
						padding: .7rem 0;
					}
				}
			}
		}

		& .busca-filtro{
			& .bloco_busca{
				grid-template-columns: repeat(1, 1fr);
				gap: 1rem;
			}
		}
	}
}

