/* MINIATURA 1 */
.miniatura-imv-mobiliado{
	top: 72px;
	left: 0px;
	display: block;
	position: absolute;
	background-color: $colorTerciary;
	padding: 5px 10px;
	color: #ffffff;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	font-weight: 600;
	border-color:rgba(#ffffff, .5);
	font-size: 14px;
	@include border-box;
}

.imoveldestaque{
	top: 46px;
	left: 0px;
	display: block;
	position: absolute;
	background-color: $colorQuarterly;
	padding: 5px 10px;
	color: $gray1;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	font-weight: 600;
	border-color:rgba(#ffffff, .5);
	font-size: 14px;
	@include border-box;
}

.linkg{
	&:hover{
		color: $colorPrimary !important;
	}
}
.pd-05{
	padding-top: 0.3rem;
}

.miniatura-lancamento {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 2rem;
	& .miniatura-imovel-img{
		border-radius: .2rem .5rem 0 0;
		display: block;
		width: 100%;
		overflow: hidden;
		padding-top: 60%;
		background-color: $gray1;
		position: relative;
		user-select: none;

		& img{
			display: block;
			width: 100%;
			position: absolute;
			left: 0;
			top: -30%;
		}
		& .miniatura-arrow{
			display: block;
			top: 50%;
			transform: translateY(-50%);
			position: absolute;
			padding: 0;
			@include border-box;

			& button {
				display: block;
				line-height: 3rem;
				width: 3rem;
				border: none;
				padding: 0;
				margin: 0;
				outline: none;
				cursor: pointer;
				font-size: 2rem;
			}
		}

		& .arrow-left{
			left: 0;
		}
		& .arrow-right{
			right: 0;
		}
	}
	
	& .miniatura-imovel-img-video{
		

		padding-top: 70%;
		position: relative;
		
		& img {
			top: 50%;
			object-fit: none;
			object-position: initial !important;
			transform: translate(0,-50%);
			
		}

		& .miniatura-video-player {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			font-size: 4rem;
			color: #ffffff;
		}
	}

	& .miniatura-informacoes {
		text-align: center;
		padding: 0 .5rem;
		@include border-box;
		&:hover{
			color: $gray1 !important;
		}

		& .left {
			color: $gray1;
			font-size: 1.2rem;
		}
		& .right {
			color: $gray1;
			font-size: 1rem;
			letter-spacing: -.05rem;
    		font-weight: 300;
		}
	}

	& .miniatura-cidade-estado{
		display: inline-block;
		width: calc(100% - 3rem);
		height: 1.5rem;
		line-height: 1.3rem;
		overflow: hpd-05idden;
		vertical-align: middle;
		margin-bottom: 1rem;
		& p{
			text-transform: none !important;
			font-size: .9rem;
			font-weight: 400;
			color: $gray2;
		}
	}

	& .miniatura-cod{
		position: absolute;
		right: 1rem;
		top: -1.4rem;
		& p{
			background-color: $colorPrimary;
			border: solid 1px $gray4;
			border-radius: .3rem;
			font-size: .9rem;
			font-weight: 500;
			color: $colorTerciary;
			padding: .2rem .5rem;
		}
	}

	& .miniatura-container-id{
		display: inline-block;
		width: 5rem;
		vertical-align: middle;
		text-align: right;
	}

	& .miniatura-imovel-endereco {
		font-style: oblique;
	}

	& .miniatura-imovel-preco {
		padding: .2rem .5rem;
		@include border-box;
		color: $gray1;
		font-size: 1.1rem;
		font-weight: 600;
		background-color: white;
		border: solid 1px $colorPrimary;
		border-radius: 2rem;
		transition: all .25s;
	}

	& .miniatura-p {
		text-align: center;
		border-radius: .1rem;
		padding: .5rem .3rem;
		color: $gray2;
		font-weight: 600;
		font-size: 1.1rem;
		&:hover{
			color: white !important;
			background-color: $colorTerciary;
			transition: .2s all;
		}
	}

	& .miniatura-imovel-detalhe{
		box-shadow: 0 5px 10px rgba(black, .2);
		display: block;
		border-left: solid 1px $gray4;
		border-right: solid 1px $gray4;
		border-radius: 0 0 .5rem .5rem;
		width: 100%;
		padding: 1rem 1rem 0 1rem;
		background-color: white;
		@include border-box;
		position: relative;
		&:hover{
			color: $gray1 !important;
		}

		& .miniatura-acao{
			position: absolute;
			right: 1rem;
			top: -1.4rem;
			& p{
				background-color: white;
				border: solid 1px $colorPrimary;
				border-radius: .3rem;
				font-size: .9rem;
				font-weight: 500;
				color: $colorPrimary;
				padding: .2rem .5rem;
			}
		}

		& .miniatura-titulo{
			display: block;
			width: 100%;
			& p{
				text-transform: uppercase;
				font-size: .9rem;
				font-weight: 600;
				color: $gray3;
				height: 2.3rem;
			}
			
		}


		& .miniatura-btn-favorito {
			position: absolute;
			top: 1rem;
			right: 1rem;
		}

		& .miniatura-imovel-btns-icons{
			display: block;
			width: 100%;
			overflow: hidden;
			min-height: 22px;
			height: 3.5rem;
			color: $gray3;

			& .miniatura-imovel-icone {
				display: inline-block;
				width: 25%;
				vertical-align: middle;
			}

			& .miniatura-informacoes{
				&:hover{
					color: $gray1 !important;
				}

				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
			}
		}

		& .miniatura-preco{
			display: inline-block;
			vertical-align: middle;
			width: 60%;
			& p{
				text-transform: initial;
				font-size: 1rem;
				font-weight: 600;
				color: $colorPrimary;
			}
		}
		& .miniatura-btn-vermais{
			display: inline-block;
			vertical-align: middle;
			width: 40%;
			& p{
				background-color: $colorPrimary;
				border-radius: .1rem;
				font-size: .8rem;
				font-weight:400;
				color: white;
				padding: .5rem;
				text-align: center;
				&:hover{
					background-color: darken($colorPrimary, 5);
				}
			}
		}
		
	}

	&:hover{
		& .miniatura-imovel-preco {
			background-color: $colorPrimary;
			color: white;
		}
	}
}

/* SÓ SE APLICA NO PC */
@media all and (min-width: 1024px) {
	.iwidth-100{
		width: 100% !important;
	}
	.iwidth-50{
		width: 50% !important;
	}
	.iwidth-33-3{
		width: 33.333333% !important;
	}
	.iwidth-25{
		width: 25% !important;
	}
	.iwidth-20{
		width: 20% !important;
	}
}

