.title_condomi{
	display: block;
	width: 100%;

	& h1{
		font-size: 2rem;
		font-weight: 600;
		color: $colorSecondary;
		text-align: center;
	}
	
	& h2{
		font-size: 2rem;
		font-weight: 600;
		color: $colorSecondary;
		text-align: center;

		& span{
			font-size: 1.3rem;
			font-weight: 400;
			color: $colorSecondary;
		}
	}

	& .under_p{
		text-align: center;
		width: 5rem;
		height: 2px;
		margin: auto;
		background-color: $colorPrimary;
		border-radius: 2rem;
	}
}

.section_servicos{
	width: 100%;
	display: block;
	background-color: transparent;

	& .container_pai {
		display: block;
		width: 100%;
		background-color: white;
		padding: 2rem;
		box-shadow: 0 0 10px 5px rgba($gray1, .1);
		border-radius: .5rem;

		& h2 {
			margin: 0;
			padding-bottom: .5rem;
		}

		& .container_servicos{
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 1.5rem;
	
			& a{
				width: 100%;
				padding: 1rem;
				border-radius: .3rem;
				transition: all .2s ease-in-out;
				border: solid 1px $colorTerciary;
				background-color: white;
		
				& .bloco_servico{
					width: 100%;
					display: block;
					text-align: left;
		
					& .img{
						width: 100%;
						display: block;
						margin: 0;
		
						& img{
							text-align: left;
							width: 45px;
							height: 45px;
						}
					}
		
					& .descricao{
						width: block;
						display: block;
						position: relative;
		
						& h2{
							color: $colorSecondary;
							font-size: .9rem;
							font-weight: 600;
							text-align: left;
							line-height: normal;
						}
		
						& p{
							padding: 0;
							color: lighten($gray2, 5);
							font-size: .9rem;
							font-weight: 400;
							text-align: left;	
							transition: all .15s;
						}
					}
				}
		
				&:hover{
					border-color: $colorPrimary;
				}
			}
		}
	}
}

.bg_busca_interna{
	background-color: white;
	display: block;
	width: 100%;

	& h2{
		text-align: center;
		color: $gray1;
		font-size: 1.8rem;
		font-weight: 400;
	}


	& .container_filtros_busca_interna{
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 1rem;

		& .grid_items{
			width: 100%;

			& .titulo-formulario{
				color: $gray2;
				font-size: .9rem;
				font-weight: 400;
				line-height: 1.5rem;
			}

			& .grid_botoes_buscar_limpar{
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: .5rem;
			}
		}
	}
}

#ux_to_top{
	position: relative;
	top: -100px;
	left: -90%;
	z-index: 999999;
}

.botao_voltar{
	background-color: white;
	border: solid 1px $colorTerciary;
	border-radius: .3rem;
	padding: .5rem 1.5rem;
	color: $gray1 !important;
	font-size: .9rem;
	font-weight: 500;
	cursor: pointer;
	transition: border-color .2s ease-in-out;

	& i{
		color: $colorPrimary;
	}

	&:hover{
		border-color: $colorPrimary;
	}
}

.title_contato{
	font-size: 1.5rem;
	font-weight: 600;
	color: $colorSecondary;
	vertical-align: middle;
	& i{
		color: $colorPrimary;
	}
}

.width_vid{
	width: 50vw !important;
}

.container_infras_buscaInterna {
	display: block;
	width: 100%;
	border: solid 1px lighten($gray4, 5);
	border-radius: .3rem;
	
	& .titleInfraGroup {
		font-size: .9rem;
		font-weight: 400;
		color: $gray1;
		padding: .7rem 1rem;
		margin: 0;
		position: relative;
		cursor: pointer;
		transition: all .2s ease-in-out;
		user-select: none;
		border-radius: .3rem;

		& i{
			position: absolute;
			right: 1.5rem;
			color: $colorPrimary;
			top: 40%;
			transition: all .2s ease-in-out;
		}

		&:hover{
			background-color: lighten($gray5, 2);
		}
	}

	& .infraGroups{
		padding: .5rem;

		& label{
			font-size: .9rem !important;
			& span{
				width: 11px !important;
				height: 11px !important;
				float: none !important;
			}
		}
	}
}

@media all and (max-width: 1023px){

	.bg_busca_interna{
	
		& h2{
			text-align: center;
			color: $gray1;
			font-size: 1.5rem;
			font-weight: 400;
		}
	
	
		& .container_filtros_busca_interna{
			grid-template-columns: repeat(1, 1fr);
			gap: 1.5rem;
		}
	}
	
	.width_vid{
		width: 80vw !important;
	}

	.title_condomi{
		& h2{
			font-size: 1.5rem;
		}
	}

	#ux_to_top{
		left: 15%;
		top: -80px;
	}

	.section_servicos{
		& .container_pai {
			& h2 {
				margin: 0;
				padding-bottom: .5rem;
			}
			
			& .container_servicos{
				display: grid;
				grid-template-columns: repeat(1, 1fr);
				gap: 1.5rem;

				& a{
					& .bloco_servico{
						& .descricao{
							& h2{
								font-size: .9rem;
							}
						}
					}
				}
			}
		}
	}
}